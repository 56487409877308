const resultaten = 
[
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.56,1\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "3 000 m",
    "plaats": "/",
    "naam": "Monique Seys"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.59.29,0\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Halve marathon",
    "plaats": "Poperinge",
    "naam": "Vanrenterghem Walter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"02.17.10,0\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Marathon",
    "plaats": "Liévin (FR)",
    "naam": "Vanrenterghem Walter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.30.07,3\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "10 000 m",
    "plaats": "Waregem",
    "naam": "Vanrenterghem Walter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.03.44,0\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Halve marathon",
    "plaats": "Poperinge",
    "naam": "Deraeve Willy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.02,61\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Lieven VanMassenhove"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,76\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Kogelstoten",
    "plaats": "Watermaal-Bosvoorde",
    "naam": "Vercruysse Joeri"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,05\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Heverlee",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-01-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,12\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Michigan (US)",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-03-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,07\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Renaat Vanoplynes"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-03-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,40\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Quentin Planchon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-04-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,03\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "100 m horden",
    "plaats": "Michigan (US)",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-05-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1424 pnt\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Driekamp",
    "plaats": "Heule",
    "naam": "Sofie Wielfaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-05-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.13.02,9\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "3 000 m",
    "plaats": "Gentbrugge",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-05-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.15.14,70\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "2 Engelse mijl",
    "plaats": "Brugge",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-05-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.14.18,41\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "2 Engelse mijl",
    "plaats": "Brugge",
    "naam": "Ria Cools"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.22,68\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "200 m",
    "plaats": "Oordegem",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Kermismeeting",
    "handgeklokt": false,
    "datum": "01-07-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,39\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Kogelstoten 4kg",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/39093/329012/#losonderdeel_2_",
    "plaats": "Poperinge",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-08-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.47,01\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "800 m",
    "plaats": "Hildesheim (DE)",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.18,57\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Oudenaarde",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-09-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,09\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Kogelstoten 3kg",
    "plaats": "Fallonstadion",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-09-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.11.03,300\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "3 000 m",
    "plaats": "Deinze",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.55,45\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "800 m",
    "plaats": "Halluin (FR)",
    "naam": "Bruneel Maxime"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "01-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.29,56\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "800 m",
    "plaats": "Halluin (FR)",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-01-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.06,70\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-02-2003",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,40\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-03-2003",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.22,84\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Koen Polley"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-03-2003",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,66\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-05-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3302 pnt\""
    },
    "einddatum": "03-05-1998",
    "categorie": "Cadetten",
    "onderdeel": "Zeskamp",
    "plaats": "Ieper",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-06-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.31,85\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Brugge",
    "naam": "Masschelein Marc"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,31\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Hoogspringen",
    "plaats": "Nieuwpoort",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.14,21\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 500 m",
    "plaats": "Nieuwpoort",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.53,81\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "3 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-06-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.46,67\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 500 m",
    "plaats": "Villeneuve-d'Ascq (FR)",
    "naam": "Van Hollebeke Fran"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.16,4\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "100 m",
    "plaats": "Waregem",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.49,34\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Poperinge",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hoogspringen",
    "plaats": "Nieuwpoort",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.36,40\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "200 m",
    "plaats": "Deinze",
    "naam": "Bossue Patrick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.27,62\""
    },
    "einddatum": "",
    "categorie": "M65",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Poperinge",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.97,57\""
    },
    "einddatum": "",
    "categorie": "M65",
    "onderdeel": "400 m",
    "plaats": "Poperinge",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.57,36\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "3 x 600 m",
    "plaats": "Poperinge",
    "naam": "Viktor Degels, Thor Van de Weghe, Robin Allemeersch"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-09-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.14.50,04\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "5 000 m",
    "plaats": "Naimette",
    "naam": "Verleyen Philippe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-09-2001",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"44,83\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Hamerslingeren",
    "plaats": "Jambes",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-02-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,29\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Thomas Veryser"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-02-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,40\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-02-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4 903 pnt\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Zevenkamp",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-03-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,99\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Gwendolien Depover"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-03-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,48\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Gewichtwerpen",
    "plaats": "St Niklaas",
    "naam": "Lieselot Tiersen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-06-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.49,01\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "4 x 60 m",
    "plaats": "Oostende",
    "naam": "Verfaillie, Clarebout, Herman, Plets"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-06-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.54,24\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "400 m",
    "plaats": "Tielt",
    "naam": "Daels Marc"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,72\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "100 m",
    "plaats": "Torhout",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1007 pnt\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Driekamp",
    "plaats": "LeBizet",
    "naam": "Emily Laheye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,80\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Verspringen",
    "plaats": "LeBizet",
    "naam": "Emily Laheye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,65\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Verspringen",
    "plaats": "LeBizet",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,34\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Verspringen",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.14.09,17\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "5 000 m",
    "plaats": "Hasselt",
    "naam": "Goudeseune Jonathan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.37,07\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Olympische aflossing",
    "plaats": "Poperinge",
    "naam": "Simon Versavel , Brent Gayse, Fiel Elslander, Jan Carly"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.45,96\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "300 m",
    "plaats": "03-07-2021",
    "naam": "Van Hollebeke Fran"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-08-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.35,08\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "300 m",
    "plaats": "Oudenaarde",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-09-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.50,77\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "4 x 100 m",
    "plaats": "Tessenderlo",
    "naam": "Devos, Braem, Masselus, Camerlynck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-09-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,92\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "100 m",
    "plaats": "Merksem",
    "naam": "Daels Marc"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.46,44\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 100 m",
    "plaats": "Lier",
    "naam": "Vanhoucke Stan, Nuytten Yaron, Versavel Simon, Bruneel Maxime"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Finale Leeuwtjescriterium",
    "handgeklokt": false,
    "datum": "03-09-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,68\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Kogelstoten 2kg",
    "link": "https://www.atletiek.nu/atleet/main/1454179/",
    "plaats": "Brugge",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "03-09-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\n[\n    {\n        \"onderdeel\": \"60 m\",\n        \"prestatie\": \"11,12\",\n        \"punten\": \"(727)\"\n    },\n    {\n        \"onderdeel\": \"1000 m\",\n        \"prestatie\": \"3.20,70\",\n        \"punten\": \"(710)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen 400g\",\n        \"prestatie\": \"30,90\",\n        \"punten\": \"(525)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten 2kg\",\n        \"prestatie\": \"13,68\",\n        \"punten\": \"(800)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,41\",\n        \"punten\": \"(579)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3341\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Vijfkamp",
    "link": "https://www.atletiek.nu/atleet/main/1454179/",
    "plaats": "Brugge",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,61\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,04\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,83\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3 020 pnt\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Vijfkamp",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-03-2001",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,27\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-03-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.51,21\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Quatacker Lore"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"10,86\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Duyck Sarah"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Belgisch Studenten Kampioenschap",
    "handgeklokt": false,
    "datum": "04-03-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,65\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Belgisch Studenten Kampioenschap",
    "handgeklokt": false,
    "datum": "04-03-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,28\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-05-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.15.01,9\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "5 000 m",
    "plaats": "Dilbeek",
    "naam": "Vanrenterghem Walter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-05-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,42\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Hamerslingeren",
    "plaats": "Tourcoing",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-06-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8,89\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "Kogelstoten 3kg",
    "plaats": "Roeselare",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-06-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,06\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "Speerwerpen 400g",
    "plaats": "Roeselare",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-06-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.20,47\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "800 m",
    "plaats": "Wervik",
    "naam": "Amber Nuytten"
  },
  {
    "geslacht": "Mannen",
    "evenement": " BvV Masters",
    "handgeklokt": false,
    "datum": "04-06-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.09,78\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/39081/400m/",
    "plaats": "Begijnendijk",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Mannen",
    "evenement": "1,067m",
    "handgeklokt": false,
    "datum": "04-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,76\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "110 m horden",
    "plaats": "Oordegem",
    "naam": "Alleman Nico"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "KVV MK",
    "handgeklokt": false,
    "datum": "04-07-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1806 pnt\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Vierkamp",
    "plaats": "Nieuwpoort",
    "naam": "Meerschout Roselinde"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-07-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,64\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Hoogspringen",
    "plaats": "Poperinge",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,26\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Discuswerpen",
    "plaats": "Melle",
    "naam": "Plets Pascal"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-08-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.46,21\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "300 m horden",
    "plaats": "Oostende",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,45\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Kogelstoten",
    "plaats": "Jambes",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,71\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Verspringen",
    "plaats": "Jambes",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-09-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.15,78\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "2 000 m steeplechase",
    "plaats": "Beveren - Nacht",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Buffalo-Trakksmeeting",
    "handgeklokt": false,
    "datum": "04-12-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.56,67\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/startgroep/665991/",
    "plaats": "Gent",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-01-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55,75\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Speerwerpen",
    "plaats": "Tielt",
    "naam": "Vandaele Frank"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-01-1997",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,81\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Amandine Plets"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-01-2003",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,88\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Nathalie Bobowski"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-01-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.26,08\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 500 m",
    "plaats": "Liévin (FR)",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-02-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.35,84\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 200 m",
    "plaats": "Gent",
    "naam": "Melsens G., VanMassenhove, Ghesquière P., Witdouck J."
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3 301 pnt\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Zeskamp",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-03-1995",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,07\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Hannes Depuydt"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-03-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,78\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Marc Daels"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-03-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.24,04\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-03-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,78\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-03-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"11,35\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Jarl Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK beloften",
    "handgeklokt": false,
    "datum": "05-03-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,88\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-04-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.06,3\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 500 m steeplechase",
    "plaats": "Ashford (GB)",
    "naam": "Werbrouck Pieter-Jan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-04-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.03,8\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 500 m steeplechase",
    "plaats": "Ashford (GB)",
    "naam": "Werbrouck Bram"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-05-2006",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.29,95\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "3 000 m",
    "plaats": "Oordegem",
    "naam": "Jaimie Demuynck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-06-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.28,7\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "200 m",
    "plaats": "Oostende",
    "naam": "Heidi Segier"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-06-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.16,94\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "110 m horden",
    "plaats": "Tessenderlo",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-06-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2441 pnt\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Vijfkamp",
    "plaats": "Izegem",
    "naam": "Inge Robyn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-06-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.04,09\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "800 m",
    "plaats": "Oudenaarde",
    "naam": "An Devos"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,03\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "100 m",
    "plaats": "Waregem",
    "naam": "Agueh Eric"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Rosine Wallez meeting",
    "handgeklokt": false,
    "datum": "05-07-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.48,31\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "2 000 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/39160/2000m/#331283_137",
    "plaats": "Oudenaarde",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-08-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.04,3\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 500 m",
    "plaats": "Waregem",
    "naam": "Vanrenterghem Walter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-08-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.09,09\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Aalter",
    "naam": "Lynn Dekeyser"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-08-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.41,32\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "600 m",
    "plaats": "Aalter",
    "naam": "Vanhoorne Morena"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-08-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.48,31\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "600 m",
    "plaats": "Aalter",
    "naam": "Demasure Sien"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-09-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.03,3\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 500 m",
    "plaats": "Tourcoing",
    "naam": "Degraeve Kris"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-09-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,60\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Oordegem",
    "naam": "Devos Jeroen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "BK cad & Scol",
    "handgeklokt": false,
    "datum": "05-09-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,60\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "BK cad & Scol",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Masters Trophy en Cad/Sch meeting",
    "handgeklokt": false,
    "datum": "05-09-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.66,70\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "400 m",
    "plaats": "Masters Trophy en Cad/Sch meeting",
    "naam": "Vandekerkhove Filiep"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.14,08\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "100 m horden",
    "plaats": "Diest",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-12-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.40,28\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "05-12-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.49,87\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-01-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.06.56,94\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Devos An"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-01-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,74\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Kogelstoten 4kg",
    "plaats": "Gent",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.56,56\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-1999",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,65\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Liévin (FR)",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.41,53\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Maxime Bruneel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.12,49\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Deleu Femke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,63\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Verspringen",
    "plaats": "Louvain-la-Neuve",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.09,88\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-03-1988",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,35\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Hoogspringen",
    "plaats": "Hoboken",
    "naam": "Glenn Melsens"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-03-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"14,99\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Joeri Vercruysse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-03-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,86\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Kogelstoten 3kg",
    "plaats": "Ieper",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-03-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"11,67\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-04-2006",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.17.25,0\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Halve marathon",
    "plaats": "Poperinge",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-05-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,81\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "100 m",
    "plaats": "Merksem",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.15.39,90\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "3 000 m snelwandelen",
    "plaats": "Tourcoing (FR)",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-06-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.39.00,0\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "10 000 m",
    "plaats": "Tielt",
    "naam": "Carine Verhaeghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-06-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.46,50\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "3 000 m",
    "plaats": "Kessel-Lo - BVV",
    "naam": "Vandenkerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "04-06-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.37,11\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "300 m",
    "plaats": "Wervik",
    "naam": "Vanhoucke Yngwie"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.35,5\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "3 000 m",
    "plaats": "Tourcoing (FR)",
    "naam": "Verleyen Philippe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.50,00\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "1 000 m",
    "plaats": "Tourcoing",
    "naam": "Witdouck Pieter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "06-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.42,7\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 80 m",
    "plaats": "Tourcoing (FR)",
    "naam": "Meuleman, S.Vandamme, Desplenter, Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,3\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "80 m",
    "plaats": "Tourcoing (FR)",
    "naam": "Rebecca Meuleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"35,07\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hamerslingeren",
    "plaats": "Torhout",
    "naam": "Lieselot Tiersen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.20,07\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "100 m horden",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,68\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1964 pnt\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Vijfkamp",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,93\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Verspringen",
    "plaats": "Dilbeek",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2870 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Vijfkamp",
    "plaats": "Dilbeek",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-09-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,01\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "100 m horden",
    "plaats": "Brugge",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-09-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,39\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Verspringen",
    "plaats": "Torhout",
    "naam": "Odent Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-09-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,05\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Nijvel",
    "naam": "Kim Vandekerckhove"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.51.00,00\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Venetie (IT)",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-01-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,88\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Dries Allemeersch"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-01-2021",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,09\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Dieter Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-03-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.58,14\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "BK CAD+SCH",
    "handgeklokt": false,
    "datum": "07-03-2010",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,99\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Verschuere Clotilde"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-05-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"11,42\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-05-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.57,90\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "300 m",
    "plaats": "Tielt",
    "naam": "Bossue Patrick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-05-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.14,82\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "800 m",
    "plaats": "Brussel",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-06-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.44,36\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "300 m horden",
    "plaats": "Heverlee",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "BvV",
    "handgeklokt": false,
    "datum": "07-06-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.32,80\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "800 m",
    "plaats": "BvV",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.33,5\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Zweedse estafette",
    "plaats": "Ieper",
    "naam": "Vandenberghe D., Goudeseune J., Clauw B., Sinnaeve K."
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"34,74\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Witdouck Pieter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,88\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Allemeersch Dries"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.44,7\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Zweedse estafette",
    "plaats": "Ieper",
    "naam": "Dekeersmaeker R, Witdouck P, Vannieuwenhuyse D, De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.38,2\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Zweedse estafette",
    "plaats": "Ieper",
    "naam": "Thevelein P., Desplenter, Meuleman, Vandamme"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.14,89\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "110 m horden",
    "plaats": "Brussel",
    "naam": "Vandaele Frank"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"6608 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Tienkamp",
    "plaats": "Ieper",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-08-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.18,35\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 000 m",
    "plaats": "Oostduinkerke",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-09-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,24\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "80 m horden",
    "plaats": "Heverlee",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-09-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.40,93\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "300 m horden",
    "plaats": "Brugge",
    "naam": "Goudeseune Jeroen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Rubenscup",
    "handgeklokt": false,
    "datum": "07-09-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,62\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Kogelstoten",
    "plaats": "Rubenscup",
    "naam": "Kimberley Vanrobaeys"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,56\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Discuswerpen",
    "plaats": "Brugge",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.43,44\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "200 m",
    "plaats": "Deinze",
    "naam": "Mulleman Chantal"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "07-10-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,78\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Gewichtwerpen",
    "plaats": "Izegem",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-01-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.13,25\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Stan Vanhoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-01-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.23,90\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Amber Nuytten"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "PK Indoor Oost- en West-Vlaanderen en Limburg",
    "handgeklokt": false,
    "datum": "08-01-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.18,05\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Van Hollebeke Fran"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-1992",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"11,94\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,94\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Kogelstoten 3kg",
    "plaats": "Gent",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.25,20\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Alexander Lietaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,34\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.24,84\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Renaud Vandenberghe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,96\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.45,51\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-03-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.13,06\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-06-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,81\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "Kogelstoten",
    "plaats": "BvV Masters",
    "naam": "Goudeseune Ives"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-07-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.31,0\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "3 000 m",
    "plaats": "Rijsel (FR)",
    "naam": "Vermeulen Marc"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-08-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.17.44,78\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "5 000 m",
    "plaats": "Ninove",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-09-1982",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2972 ptn\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Vijfkamp",
    "plaats": "Gent",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-09-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.18,53\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 500 m",
    "plaats": "Heizel",
    "naam": "Deknudt Bart"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "08-09-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,56\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Tielt",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-01-2005",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,4\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Sien Demasure"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-01-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.24,77\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Amber Nuytten"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-02-1992",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,74\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Els Bouchaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,32\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Thor Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,42\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Jarl Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-04-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.22.29,73\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "5 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-04-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.26.15,72\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "5 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-05-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.10,89\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m horden",
    "plaats": "Tielt",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-05-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,34\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m horden",
    "plaats": "Tielt",
    "naam": "Tiffanie Verbeke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-06-2001",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,70\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hoogspringen",
    "plaats": "Izegem",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-06-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.12.57,25\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "3 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Ria Cools"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.22,13\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 400 m",
    "plaats": "Jambes",
    "naam": "Elslander F., Vanhoucke S., Gayse B., Versavel S."
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.14,89\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "100 m horden",
    "plaats": "Ieper",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"45,24\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2321 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Vijfkamp",
    "plaats": "Ieper",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2947 pnt\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Vijfkamp",
    "plaats": "Ieper",
    "naam": "Bieke Michiel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4025 pnt\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Zevenkamp",
    "plaats": "Ieper",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-08-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,62\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hoogspringen",
    "plaats": "Heverlee",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-08-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,50\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Jambes",
    "naam": "Decroix Nick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-08-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"49,08\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hamerslingeren",
    "plaats": "Gentbrugge",
    "naam": "Neyt Nick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-08-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.44,22\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "300 m",
    "plaats": "Brasschaat",
    "naam": "Sofie Busschaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Medaillemeeting",
    "handgeklokt": false,
    "datum": "09-08-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,18\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hoogspringen",
    "plaats": "Medaillemeeting",
    "naam": "Kimberley Vanrobaeys"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-08-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.41,87\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "600 m",
    "plaats": "/",
    "naam": "Nuytten Amber"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Vakantiemeeting",
    "handgeklokt": false,
    "datum": "09-08-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.36,00\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "300 m",
    "plaats": "Oudenaarde",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Vakantiemeeting",
    "handgeklokt": false,
    "datum": "09-08-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.54,15\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 000 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/39161/1000m/#331293_9",
    "plaats": "Oudenaarde",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-09-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.24,66\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "200 m",
    "plaats": "Beveren",
    "naam": "Daels Marc"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "09-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,76\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Verspringen",
    "plaats": "Kessel-Lo",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-01-1988",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.12,15\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 500 m",
    "plaats": "Liévin (FR)",
    "naam": "Kris Degraeve"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-02-2012",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.59,49\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Yngwie Vanhoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.28,83\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Alexander Lietaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.54,20\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,15\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,31\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Acou Anne-Marie"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.32,00\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Acou Anne-Marie"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.55,58\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Jan Carly"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-03-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,33\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-05-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.55,4\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 000 m",
    "plaats": "Aalst",
    "naam": "Erna Joye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-05-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.26,02\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "200 m",
    "plaats": "Oordegem",
    "naam": "Clotilde VERSCHUERE"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.26.50,46\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "5 000 m snelwandelen",
    "plaats": "?",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-06-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.34,88\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "200 m",
    "plaats": "Beveren",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.55,33\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "400 m",
    "plaats": "Herentals",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,72\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Hoogspringen",
    "plaats": "Herentals",
    "naam": "Carly Jan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.55.29\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Tilburg (NL)",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"51,87\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Speerwerpen",
    "plaats": "Izegem",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2804 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Vijfkamp",
    "plaats": "Izegem",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,92\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hoogspringen",
    "plaats": "Izegem",
    "naam": "Hofland Remy"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,23\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Kogelstoten 4kg",
    "plaats": "Izegem",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4135 pnt\""
    },
    "einddatum": "11-07-1999",
    "categorie": "Scholieren",
    "onderdeel": "Zevenkamp",
    "plaats": "Izegem",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-07-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16,63\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Hamerslingeren",
    "plaats": "Izegem",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-08-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.37,50\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "1 000 m snelwandelen",
    "plaats": "Heule",
    "naam": "Anneke Bouchaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.01,98\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Vilvoorde",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-09-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.28,6\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 000 m",
    "plaats": "Aalst",
    "naam": "Degraeve Kris"
  },
  {
    "geslacht": "Mannen",
    "evenement": "0,914m",
    "handgeklokt": false,
    "datum": "10-09-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.14,76\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "110 m horden",
    "plaats": "Oordegem",
    "naam": "Decroix Nick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-12-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.45,07\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "10-12-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.48,41\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Elias Vanhoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.40,88\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 200 m",
    "plaats": "Gent",
    "naam": "Simon Versavel, Stan Vanhoucke, Yaron Nuytten, Maxime Bruneel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,59\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-03-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,31\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-03-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,95\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-03-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2766 ptn\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Vijfkamp",
    "plaats": "Gent",
    "naam": "Lien Vanzieleghem"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-03-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.12,88\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Klaas Allemeersch"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-03-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.06,92\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "An Devos"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-03-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,91\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Jarl Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-05-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,80\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Nijvel",
    "naam": "Devos Dieter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Masters (dag 1)",
    "handgeklokt": false,
    "datum": "11-06-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.29.41,08\""
    },
    "einddatum": "",
    "categorie": "M65",
    "onderdeel": "5 000 m",
    "plaats": "Brugge",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-06-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.20,52\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "3 x 600 m",
    "plaats": "Roeselare",
    "naam": "I. Bouton, T. Van Cauwenberghe, T. Spriet"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8,54\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Kogelstoten 4kg",
    "plaats": "Ieper",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,10\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Tine Witdouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,16\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Discuswerpen",
    "plaats": "Ieper",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"52,30\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"18,48\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "Discuswerpen",
    "plaats": "Ieper",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,67\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "Kogelstoten 4kg",
    "plaats": "Ieper",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,01\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "100 m",
    "plaats": "Ieper",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.15.58,34\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "5 000 m",
    "plaats": "Ieper",
    "naam": "Voet Bjorn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.27.42,34\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "5 000 m",
    "plaats": "Ieper",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,25\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"19,98\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Speerwerpen 600g",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-07-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"31,72\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Discuswerpen",
    "link": "https://www.atletiek.nu/atleet/main/1420564/",
    "plaats": "Nieuwpoort",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-08-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.07,52\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 000 m",
    "plaats": "Aalter",
    "naam": "Lore QUATACKER"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-09-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,26\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Kogelstoten 3kg",
    "plaats": "Nijvel",
    "naam": "Manuella RomeroFernandez"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.39,23\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "300 m horden",
    "plaats": "Hasselt",
    "naam": "Devos Dieter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.22,01\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 800 m",
    "plaats": "Vilvoorde",
    "naam": "WielfaertSis., Faghel, Desplenter, Witdouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "11-09-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.53,83\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 500 m",
    "plaats": "Oordegem",
    "naam": "Lieselot Dumoulin"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Marathon",
    "handgeklokt": false,
    "datum": "11-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"02.34.40,00\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Marathon",
    "plaats": "Antwerpen",
    "naam": "Voet Bjorn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-01-2002",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,61\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Dieter Devos"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-01-2008",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,22\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Brent Gayse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-01-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.07,82\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 500 m",
    "plaats": "Liévin (FR)",
    "naam": "Kurt Engelbert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-02-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.41,22\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 500 m steeplechase",
    "plaats": "Oordegem",
    "naam": "Vandevoorde Kennu"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.10,14\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-03-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.01,96\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Koen De Ridder"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-05-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"54,54\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Speerwerpen",
    "plaats": "Lebbeke",
    "naam": "Vandaele Frank"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-06-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"40,12\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Discuswerpen",
    "plaats": "Lebbeke",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Masters",
    "handgeklokt": false,
    "datum": "12-06-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.26,05\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 500 m",
    "plaats": "Brasschaat",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-07-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.25,70\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "200 m",
    "plaats": "Ieper",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-07-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3897 pnt\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Zevenkamp",
    "plaats": "Ieper",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.51,98\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Brussel",
    "naam": "Vanhoucke Stan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-08-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.10,00\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "2 000 m",
    "plaats": "Tielt",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-09-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.02,22\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "2 000 m",
    "plaats": "Tielt",
    "naam": "Vanhoucke Yngwie"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-10-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.38.58,9\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "10 000 m",
    "plaats": "Lokeren",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-02-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,8\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Régis Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.18,00\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "3 000 m",
    "plaats": "Vucht (NL)",
    "naam": "Goudeseune Jonathan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-06-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.95,92\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "400 m",
    "plaats": "/",
    "naam": "Mulleman Chantal"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Rubenscup",
    "handgeklokt": false,
    "datum": "13-06-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.21,17\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "150 m horden",
    "plaats": "Rubenscup",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-07-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"1439 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Driekamp",
    "plaats": "Oostende",
    "naam": "Polley Koen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-07-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.07,49\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "1 500 m",
    "plaats": "Ieper",
    "naam": "Dekeersmaecker Rudy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-08-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.48,78\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 500 m",
    "plaats": "Ertvelde",
    "naam": "Goudeseune Jonathan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Leeuwtjescriterium",
    "handgeklokt": false,
    "datum": "13-08-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,73\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Verspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36592/Ver/",
    "plaats": "Beernem",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-09-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.12,4\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "3 000 m",
    "plaats": "Brussel",
    "naam": "Erna Joye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-09-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.14.56\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Halve marathon",
    "plaats": "Poperinge",
    "naam": "Carine Verhaeghe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-09-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.41,85\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "300 m",
    "plaats": "Deinze",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-09-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.17,27\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "1 000 m",
    "plaats": "Izegem",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,42\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Hoogspringen",
    "plaats": "Izegem",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "13-10-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1171 pnt\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Vijfkamp",
    "plaats": "Heule",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-01-1995",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.53,36\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Gent",
    "naam": "Wielfaert Sof., Bouchaert, Ververken, Thevelein"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-01-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,87\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Frank Vandaele"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-02-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"927 pnt\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Driekamp",
    "plaats": "Gent",
    "naam": "Jeroen Goudeseune"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-02-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,16\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-02-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.39,01\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Lars Oosterlinck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-02-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.07,91\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-02-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,87\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Bieke Michiel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-02-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,58\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Bieke Michiel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-03-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.07,35\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-03-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.38,37\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-05-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.22,32\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "200 m",
    "plaats": "Oostende",
    "naam": "Philippe Bouche"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-06-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.18.40,9\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "5 000 m",
    "plaats": "Heule",
    "naam": "Carine Verhaeghe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-06-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,16\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Hoogspringen",
    "plaats": "Roeselare",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Masters",
    "handgeklokt": false,
    "datum": "14-06-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.18,53\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 500 m",
    "plaats": "BK Masters",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,87\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hamerslingeren 4kg",
    "plaats": "Ninove",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.24,97\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "200 m",
    "plaats": "Évry (FR)",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,67\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,67\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2067 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Vijfkamp",
    "plaats": "Ieper",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.16,80\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "2 000 m",
    "plaats": "Oudenaarde",
    "naam": "Vanhoucke Stan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.52,17\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "400 m",
    "plaats": "Oudenaarde",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-08-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,04\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Verspringen",
    "plaats": "Zwevegem",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.36,98\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "2 000 m",
    "plaats": "Duffel",
    "naam": "Declerck Peter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.56,70\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "300 m",
    "plaats": "Aalter",
    "naam": "Peene Christine"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "12-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.37,19\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "300 m",
    "plaats": "Brussel",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "14-09-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.13.32,66\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "2 Engelse mijl",
    "plaats": "Aalst",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-01-2011",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.44,69\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Yngwie Vanhoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.53,57\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Pieter Witdouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,48\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Thor Van de Weghe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-04-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.10,47\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "80 m",
    "plaats": "Ieper",
    "naam": "Michiel Bieke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-05-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,59\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Kogelstoten",
    "plaats": "Waregem",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-05-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,31\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "100 m",
    "plaats": "Waregem",
    "naam": "Witdouck Jan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-05-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,24\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Brugge",
    "naam": "Vercruysse Joeri"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-05-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55,30\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Speerwerpen",
    "plaats": "Vilvoorde",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.22,12\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "200 m",
    "plaats": "Oordegem",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.09,07\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "3 x 600 m",
    "plaats": "Roeselare",
    "naam": "Van de Weghe  Thor, Creus Gust, Degels Viktor"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Masters",
    "handgeklokt": false,
    "datum": "15-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.59,65\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "400 m horden",
    "plaats": "BK Masters",
    "naam": "Carly Jan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-07-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.54,48\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "300 m",
    "plaats": "Zwevegem",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-07-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.39,39\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Zwevegem",
    "naam": "Ria Cools"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-07-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,89\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Kogelstoten",
    "plaats": "Wervik",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-08-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.19,9\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "1 000 m",
    "plaats": "Brugge",
    "naam": "Depuydt Hannes"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-08-2001",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,30\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Heusden",
    "naam": "Decroix Nick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-08-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.58,31\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "400 m",
    "plaats": "Kessel-Lo",
    "naam": "Vanhoorne Morena"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-08-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.21,96\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 500 m steeplechase",
    "plaats": "Kessel-Lo",
    "naam": "Amber Nuytten"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,05\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Brugge",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.26,32\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "200 m",
    "plaats": "Brugge",
    "naam": "Vanhoorne Morena"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2854 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Vijfkamp",
    "plaats": "Watermaal-Bosvoorde",
    "naam": "Alleman Nico"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,15\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "110 m horden",
    "plaats": "Merksem",
    "naam": "Vandaele Frank"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.68,93\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "400 m horden",
    "plaats": "Tampere (FI)",
    "naam": "Siegt Derous"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.08,95\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "60 m",
    "plaats": "Izegem",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2096 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Vierkamp",
    "plaats": "Izegem",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.55,20\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "400 m horden",
    "plaats": "Moeskroen",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.17,65\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 800 m",
    "plaats": "Moeskroen",
    "naam": "Maxime Bruyneel, Simon Versavel, Brent Gayse, Stan Vanhoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"51,34\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Speerwerpen",
    "plaats": "Moeskroen",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"51,34\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Speerwerpen",
    "plaats": "Moeskroen",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,6\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Nico Alleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,40\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Nico Alleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-02-2003",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.50,64\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Koen Polley"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-04-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,34\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Speerwerpen 600g",
    "plaats": "Ieper",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-04-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.10,60\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "800 m",
    "plaats": "Ieper",
    "naam": "Verdonck Dieter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Spring-time meeting",
    "handgeklokt": false,
    "datum": "16-04-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,85\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Speerwerpen 400g",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37297/Speer/#275757_18",
    "plaats": "Ieper",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Mannen",
    "evenement": "FLAC Ieper Springtime meeting",
    "handgeklokt": false,
    "datum": "16-04-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"20,57\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Vortex",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/38905/Vortex/",
    "plaats": "Ieper",
    "naam": "Devos Leon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-05-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,58\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Discuswerpen",
    "plaats": "Moeskroen",
    "naam": "Witdouck Jan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-05-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,11\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Turnhout",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-05-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.54,41\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m horden",
    "plaats": "Sint-Niklaas",
    "naam": "Ghesquière Peter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-05-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.21,81\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 400 m",
    "plaats": "Sint-Niklaas",
    "naam": "Bouche Ph., Ghesquière P., Nollet P., Hofland R."
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-06-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.68,25\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "4 x 100 m",
    "plaats": "Hasselt",
    "naam": "Pauwels M., Vanlerberghe., Pannekoucke R., Waignien"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-08-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.12.22,1\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "2 000 m snelwandelen",
    "plaats": "Ieper",
    "naam": "Manuella RomeroFernandez"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-08-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.13.38,0\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Halve marathon",
    "plaats": "Zottegem",
    "naam": "Vannieuwenhuyse Daniël"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,37\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Brussel",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-09-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.32,4\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 000 m",
    "plaats": "Duinkerke",
    "naam": "Degraeve Kris"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"03.13.13,0\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Marathon",
    "plaats": "Torhout",
    "naam": "Lemaire Willy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "16-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"02.29.57,0\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Marathon",
    "plaats": "Torhout",
    "naam": "Storme Marnick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-02-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4 722 pnt\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Zevenkamp",
    "plaats": "Gent",
    "naam": "Peter Ghesquière"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-02-1999",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,17\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Kogelstoten 4kg",
    "plaats": "Gent",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.25,88\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-02-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.16.08,17\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "3 km snelwandelen",
    "plaats": "Gent",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Jeugdmeerkamp",
    "handgeklokt": false,
    "datum": "17-05-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.09,30\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "60 m",
    "plaats": "Jeugdmeerkamp",
    "naam": "Kimberley Vanrobaeys"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-06-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"1.535 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Driekamp",
    "plaats": "Heule",
    "naam": "Alleman Nico"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "BK Masters",
    "handgeklokt": false,
    "datum": "17-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.81,77\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "400 m",
    "plaats": "BK Masters",
    "naam": "Devos An"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.35,01\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Ninove",
    "naam": "Lietaert Alexander"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.17,94\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "80 m horden",
    "plaats": "Dilbeek",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-07-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.36,46\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 500 m",
    "plaats": "Oordegem",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-08-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.16,7\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "800 m",
    "plaats": "Aalst",
    "naam": "Erna  Joye"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,90\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hoogspringen",
    "plaats": "Sint-Niklaas",
    "naam": "Vandaele Frank"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-08-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.45,13\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "800 m",
    "plaats": "Ninove",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-08-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11840\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 uur",
    "plaats": "Kortrijk",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-08-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11477\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 uur",
    "plaats": "Diksmuide",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-08-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.53,96\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "300 m",
    "plaats": "Aalter",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,26\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Hockeywerpen",
    "plaats": "Brugge",
    "naam": "Devos Joeroen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-09-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.27,36\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "2 Engelse mijl",
    "plaats": "Ertvelde",
    "naam": "Vanhoucke Yngwie"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,37\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Verspringen",
    "plaats": "Tielt",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.05,34\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "2 000 m",
    "plaats": "Tielt",
    "naam": "Femke Deleu"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "17-10-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"02.38.52,00\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Marathon",
    "plaats": "Brugge",
    "naam": "Decoster Frédéric"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.18,51\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.24,95\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "4 x 200 m",
    "plaats": "Gent",
    "naam": "Bouton Ibe, Van Cauwenberghe Teshafun, Spriet Timon, Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-04-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.46.35\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Halve marathon",
    "plaats": "Langemark",
    "naam": "Ria Cools"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-05-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,6\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-05-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.17,04\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/39386/800m/#337618_6",
    "plaats": "Gent",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-06-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.57,17\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "4 x 400 m",
    "plaats": "Deinze",
    "naam": "Ghesquière R., Daels M., Masschelein M., Plets P."
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-06-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10.01.27,0\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "100 km",
    "plaats": "Torhout",
    "naam": "Depoortere Ludo"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-06-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,66\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "100 m",
    "plaats": "Oudenaarde",
    "naam": "Clotilde VERSCHUERE"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-07-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.34,34\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "200 m",
    "plaats": "Turku (FI)",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-07-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.04,26\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 Engelse mijl",
    "plaats": "/",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-08-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.00,10\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "800 m",
    "plaats": "Aalst",
    "naam": "Deknudt Bart"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-08-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"35,92\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hockeywerpen",
    "plaats": "Oostende",
    "naam": "Witdouck Pieter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.08,8\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "1 000 m",
    "plaats": "Waregem",
    "naam": "Witdouck Pieter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-08-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.47,08\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m",
    "plaats": "Izegem",
    "naam": "Bouche Philippe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-08-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.28,28\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Nieuwpoort",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-08-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.07,10\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 000 m",
    "plaats": "Zele",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"09.26.57,0\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "100 km",
    "plaats": "Torhout",
    "naam": "Casteleyn Dirk"
  },
  {
    "geslacht": "Mannen",
    "evenement": "PK",
    "handgeklokt": false,
    "datum": "18-09-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.58,85\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Machelen",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.54,69\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "400 m horden",
    "plaats": "Herve",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.48,93\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "400 m",
    "plaats": "Herve",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-12-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,77\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Régis Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-12-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,53\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Régis Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "18-12-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.58,30\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Kris Degraeve"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-01-2003",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,3\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Sien Demasure"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-02-1995",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,81\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Joeri Vercruysse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-02-1995",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,26\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Inge Robyn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Allianz BK A.C. Indoor",
    "handgeklokt": false,
    "datum": "19-02-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.31.49,36\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "5 km snelwandelen",
    "plaats": "Gent",
    "naam": "Vandenberghe Renaud"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Indoor",
    "handgeklokt": false,
    "datum": "25-02-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.24,29\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 500 m",
    "link": "https://www.atletiek.nu/wedstrijd/startgroep/682628/",
    "plaats": "Gent",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Allianz BK A.C. Indoor",
    "handgeklokt": false,
    "datum": "19-02-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,62\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Mylle Ine"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "BvV",
    "handgeklokt": false,
    "datum": "19-04-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,86\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Kogelstoten",
    "plaats": "BvV",
    "naam": "Lisa Vandendorpe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-05-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.49,75\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "400 m",
    "plaats": "Kortrijk",
    "naam": "Polley Koen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-05-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.18,75\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "110 m horden",
    "plaats": "Lokeren",
    "naam": "Carly Jan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-07-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.42,6\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 500 m",
    "plaats": "Tourcoing (FR)",
    "naam": "Erna Joye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-07-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,24\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "Speerwerpen 500g",
    "plaats": "Turku",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.41,4\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "1 000 m",
    "plaats": "Oostende",
    "naam": "Jaimie Demuynck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-08-2001",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,87\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Kogelstoten",
    "plaats": "Tielt",
    "naam": "Lieselot Tiersen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"37,91\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hamerslingeren",
    "plaats": "Roeselare",
    "naam": "Birgit Dieryck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,88\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Gewichtwerpen",
    "plaats": "Roeselare",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,13\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Zwevegem",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-09-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.52,76\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 100 m",
    "plaats": "Gaurain",
    "naam": "Moulin, Buyse, Ververken, RomeroFernandez"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-09-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.15,22\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 800 m",
    "plaats": "Gaurain",
    "naam": "DeMulder, RomeroFernandez, Ververken, Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-09-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.18.51,91\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "5 000 m",
    "plaats": "Ninove",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-09-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.35,21\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "2 Engelse mijl",
    "plaats": "English Meeting",
    "naam": "Quatacker Feinse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-10-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,91\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Hamerslingeren",
    "plaats": "St Niklaas",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-12-2004",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.11,61\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Simon Odent"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-12-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,90\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "19-12-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.10,32\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Yaron Nuytten"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,01\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Régis Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-01-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.23,04\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-01-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,67\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-02-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.53,86\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 200 m",
    "plaats": "Gent",
    "naam": "Coigne, Bouchaert, Moerman, WielfaertSof."
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-02-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.53,48\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 200 m",
    "plaats": "Gent",
    "naam": "Moulin, RomeroFernandez, Daxhelet, Buyse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-02-2002",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,27\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Frank Vandaele"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-04-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"5172 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Tienkamp",
    "plaats": "Ieper",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-05-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.56,71\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.59,25\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "400 m",
    "plaats": "Zwevegem",
    "naam": "Verdonck Dieter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-06-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.51,94\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "800 m",
    "plaats": "Oordegem",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-06-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.39,46\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "300 m",
    "plaats": "Wervik",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-07-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.24,7\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "200 m",
    "plaats": "Brugge",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"5559 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Negenkamp",
    "plaats": "Vught (NL)",
    "naam": "Ghesquière Peter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.05,05\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "2 000 m steeplechase",
    "plaats": "Zwevegem",
    "naam": "Masschelein Marc"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.43,67\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "300 m",
    "plaats": "Zwevegem",
    "naam": "Sabine Thevelein"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2003 pnt\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Vierkamp",
    "plaats": "Moeskroen",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.57,80\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 500 m",
    "plaats": "Nijvel",
    "naam": "Verleyen Philippe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.35,58\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 000 m",
    "plaats": "Zwevegem",
    "naam": "Masschelein Marc"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,35\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "100 m",
    "plaats": "Ninove",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,61\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Verspringen",
    "plaats": "Ninove",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-08-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.02,26\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 000 m",
    "plaats": "Deinze",
    "naam": "An Devos"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BK Meerkamp",
    "handgeklokt": false,
    "datum": "20-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4851 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Achtkamp",
    "plaats": "BK Meerkamp",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,10\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Hockeywerpen",
    "plaats": "Ieper",
    "naam": "Anneke Bouchaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Stadium",
    "handgeklokt": false,
    "datum": "20-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,32\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Discuswerpen",
    "plaats": "Stadium",
    "naam": "Nys Fréderique"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.22,3\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "2 000 m steeplechase",
    "plaats": "Dilbeek",
    "naam": "Werbrouck Bram"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,20\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Verspringen",
    "plaats": "LeBizet",
    "naam": "Magaly Clarebout"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12456\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 uur",
    "plaats": "Kortrijk",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"39,16\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Discuswerpen",
    "plaats": "Nieuwpoort",
    "naam": "Neyt Nick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,79\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "Discuswerpen",
    "plaats": "Ninove",
    "naam": "Plets Pascal"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,05\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "Kogelstoten",
    "plaats": "Ninove",
    "naam": "Plets Pascal"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "20-12-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.00,62\""
    },
    "einddatum": "",
    "categorie": "M65",
    "onderdeel": "1 000 m",
    "plaats": "Oostduinkerke",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"11,76\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Pieter Witdouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,83\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Frank Vandaele"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,46\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Peter Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4 954 pnt\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Zevenkamp",
    "plaats": "Gent",
    "naam": "Frank Vandaele"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"10,83\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.54,76\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Yngwie Vanhoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-02-2015",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.15.38,84\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "3 km snelwandelen",
    "plaats": "Gent",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-03-2009",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.03,87\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "BAU Iwein"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-04-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.35,64\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 000 m",
    "plaats": "Kortrijk",
    "naam": "Vanhoucke Yngwie"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-04-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.49.07,42\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "10 000 m",
    "plaats": "Kortrijk",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-04-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,56\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Hoogspringen",
    "plaats": "Oostende",
    "naam": "Bieke Michiel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.45,33\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "300 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36579/300m/",
    "plaats": "Izegem",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-06-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"19,98\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Discuswerpen",
    "plaats": "Eeklo",
    "naam": "Amandine Plets"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-07-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"33,64\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Discuswerpen",
    "plaats": "Merksem",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-07-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3205 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Vijfkamp",
    "plaats": "Oordegem",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,82\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hamerslingeren",
    "plaats": "Ieper",
    "naam": "Plets Pascal"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.50,34\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 000 m",
    "plaats": "Zele",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-08-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.56,72\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 500 m",
    "plaats": "Roeselare",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-09-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.43,24\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 500 m",
    "plaats": "Brussel",
    "naam": "Jaimie Demuynck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-12-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.09,21\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Jeroen Goudeseune"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-12-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,85\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Dries Allemeersch"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "21-12-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,10\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-01-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,06\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-02-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.52,16\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Pieter Witdouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-02-2013",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.10,78\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-02-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.32,00\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Yngwie Vanhoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-02-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,35\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-03-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,8\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Allan Lechantre"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-03-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,10\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Vanrobaeys Kimberley"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-03-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,46\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Kimberley Vanrobaeys"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-03-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,55\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Lisa Vandendorpe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-03-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.12,67\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Lahousse Louise"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-04-2006",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1441 pnt\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Vierkamp",
    "plaats": "Roeselare",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-04-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.73,87\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "400 m",
    "plaats": "Kortrijk",
    "naam": "Delva Evelyne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-05-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,12\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Verspringen",
    "plaats": "Oordegem",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-06-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2942 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Vijfkamp",
    "plaats": "Oordegem",
    "naam": "Neyt Ken"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.17,33\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "150 m",
    "plaats": "Kortrijk",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.20,59\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "150 m",
    "plaats": "Kortrijk",
    "naam": "Duyck Sarah"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.19,70\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "150 m",
    "plaats": "Kortrijk",
    "naam": "Vanhoorne Murena"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-08-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,73\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Speerwerpen",
    "plaats": "Herve",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-08-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.20,73\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "2 000 m steeplechase",
    "plaats": "Zwevegem",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-08-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,13\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Nieuwpoort",
    "naam": "Kim Vandekerckhove"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,96\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Tine Witdouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,28\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Tine Witdouck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"29,00\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Discuswerpen",
    "plaats": "Ieper",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-09-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.44,32\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 100 m",
    "plaats": "Nijvel",
    "naam": "Devos D., Agueh E., Vercruysse J., De Ponthière F"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-12-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.08.32,03\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "3 000 m",
    "plaats": "Gent",
    "naam": "Kris Degraeve"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-12-2007",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.15,75\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "BAU Iwein"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-12-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.34,05\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Gent",
    "naam": "Yngwie Vanhoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "22-12-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,22\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2 430 pnt\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Vijfkamp",
    "plaats": "Gent",
    "naam": "Dries Allemeersch"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.25,16\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Régis Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,4\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Régis Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.44,29\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Peter Ghesquière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,34\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Peter Ghesquière"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-02-2013",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,82\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Kim Vandekerckhove"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-02-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.36,44\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Brent Gayse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-04-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,05\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Roeselare",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-05-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,37\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Verspringen",
    "plaats": "Siegenwald-Geisweid",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-05-1998",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.66,41\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "400 m horden",
    "plaats": "Zwevegem",
    "naam": "Caroline-Marie Tas"
  },
  {
    "geslacht": "Mannen",
    "evenement": "PK Hamer",
    "handgeklokt": false,
    "datum": "23-05-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"44,70\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hamerslingeren",
    "plaats": "Torhout",
    "naam": "Neyt Nick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-06-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,17\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Vilvoorde",
    "naam": "Baudouin Didier"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-06-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,81\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Discuswerpen",
    "plaats": "Wervik",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,27\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hoogspringen",
    "plaats": "Nieuwpoort",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.56.16,29\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "10 000 m snelwandelen",
    "plaats": "/",
    "naam": "Annelies Sarrazin"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "KVV",
    "handgeklokt": false,
    "datum": "23-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,33\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Hamerslingeren",
    "plaats": "KVV",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-08-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.19,86\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "150 m",
    "plaats": "Oostende",
    "naam": "Decroix Nick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-08-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.16,44\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "100 m",
    "plaats": "Zwevegem",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-08-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.34,63\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "200 m",
    "plaats": "Zwevegem",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-08-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.25,41\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "800 m",
    "plaats": "Oordegem",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-08-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.48,87\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 000 m",
    "plaats": "Oost-West",
    "naam": "MULLEMAN Chantal"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"01.35.24,0\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Halve marathon",
    "plaats": "Ieper",
    "naam": "Canepeel Serge"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.37,5\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "1 000 m snelwandelen",
    "plaats": "St.Baafs-Vijve",
    "naam": "Tine Witdouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.04,12\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "4 x 60 m",
    "plaats": "Brugge",
    "naam": "Cogghe, Vervisch, Nollet, Depuydt"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,77\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hamerslingeren",
    "plaats": "Nijvel",
    "naam": "Neyt Nick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.26,13\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "3 000 m",
    "plaats": "Oordegem",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.43,73\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 000 m",
    "plaats": "Douchy (FR)",
    "naam": "Vanhoucke Stan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.43,01\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "4 x 80 m",
    "plaats": "Nijvel",
    "naam": "Michiel Bieke, Michiel Cato, Planquette Marit, Duyck Sarah"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.13,87\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "2 000 m",
    "plaats": "Tielt",
    "naam": "Femke Deleu"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-10-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.09,00\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 500 m",
    "plaats": "Booischot",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-10-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.56,62\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-10-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"33,32\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Hamerslingeren",
    "plaats": "St Niklaas",
    "naam": "Birgit Dieryck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-12-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.10.32,91\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "3 000 m",
    "plaats": "Liévin (FR)",
    "naam": "Thibaut Vanspranghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-12-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.39,01\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Liévin (FR)",
    "naam": "Deruytter, B. Masselus, Vanspranghe, G. Masselus"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "23-12-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.01.35,60\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Liévin (FR)",
    "naam": "Maillard, Sedeyn, Sercu J., DePonthière"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-01-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.60,37\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-02-2001",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Nick Decroix"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.46,69\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Koen De Ridder"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.21,12\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "150 m",
    "plaats": "Gent",
    "naam": "Elias Vanhoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.10,38\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Michelle Vanisacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.45,20\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-02-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.11,60\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Sien Demasure"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Belgisch Studenten Kampioenschap",
    "handgeklokt": false,
    "datum": "24-02-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.07,87\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Mylle Ine"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-04-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,50\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Ieper",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-05-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,6\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Heule",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-05-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"31,93\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Speerwerpen",
    "plaats": "Wervik",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-05-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.00,88\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "2 000 m",
    "plaats": "Tielt",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-06-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,20\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "100 m",
    "plaats": "Kortrijk",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-06-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,83\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Verspringen",
    "plaats": "Kortrijk",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-06-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.17,01\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "100 m",
    "plaats": "Kortrijk",
    "naam": "Boudewijn Polley"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.43,81\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 200 m",
    "plaats": "Kortrijk",
    "naam": "Vanhoucke Stan, Nuytten, Versavel Simon, Bruneel Maxime"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,08\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Kogelstoten",
    "plaats": "Kortrijk",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.19,56\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "150 m",
    "plaats": "Zwevegem",
    "naam": "Michiel Bieke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.29,6\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "3 000 m",
    "plaats": "Waregem",
    "naam": "Catharina Gouwy"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.52,73\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 100 m",
    "plaats": "Zwevegem",
    "naam": "Moulin, RomeroFernandez, Ververken, Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.16,30\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 400 m",
    "plaats": "Zwevegem",
    "naam": "Coigné, RomeroFernandez, Ververken, Buyse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2463 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Vierkamp",
    "plaats": "Oostende",
    "naam": "Witdouck Pieter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"6733 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Tienkamp",
    "plaats": "Herve",
    "naam": "Vandaele Frank"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.53,54\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "300 m",
    "plaats": "Nieuwpoort",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.15,05\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "2 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.12,60\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "2 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-09-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,20\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Verspringen",
    "plaats": "Eeklo",
    "naam": "Alleman Nico"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11743\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 uur",
    "plaats": "Oudenaarde",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "24-09-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,14\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Finale BvV Pup/Min",
    "handgeklokt": false,
    "datum": "24-09-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"39,69\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Speerwerpen",
    "plaats": "Ieper",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-01-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,69\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.10,38\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Femke Deleu"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.58,65\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.21,10\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "150 m",
    "plaats": "Gent",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-04-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.21,9\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 400 m",
    "plaats": "Zwevegem",
    "naam": "Daxhelet/RomeroFernandez/Verfaillie/Buyse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-04-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.23,75\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "800 m",
    "plaats": "Tielt",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "PK",
    "handgeklokt": false,
    "datum": "25-04-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.32,7\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 500 m",
    "plaats": "PK",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-05-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.42,92\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "4 x 80 m",
    "plaats": "Brugge",
    "naam": "Decroix, Witdouck, Wielfaert, Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-05-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.19.24,5\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "3 000 m snelwandelen",
    "plaats": "St.Ghislain",
    "naam": "Katia Wydhooge"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-05-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.43,99\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "300 m",
    "plaats": "Tielt",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-05-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.67,43\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "300 m",
    "plaats": "/",
    "naam": "Mulleman Chantal"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-05-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.44,15\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "300 m",
    "plaats": "Tielt",
    "naam": "Michiel Bieke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.32,04\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "200 m",
    "plaats": "Zwevegem",
    "naam": "Accou Anne-Marie"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.35,2\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Oostende",
    "naam": "Bouton Geert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,62\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Speerwerpen",
    "plaats": "Waregem",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.17.17,98\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Zwevegem",
    "naam": "Werbrouck B., Masschelein M., Verleyen Ph., Nollet P."
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.52,43\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 100 m",
    "plaats": "Zwevegem",
    "naam": "Bobowsky N., Vanslembrouck H., Vervisch M., Thevelein P."
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.49,61\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Zwevegem",
    "naam": "Bobowsky N., Vanslembrouck H., Vervisch M., Thevelein P."
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.44,99\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Gent",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.36.48,55\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "10 000 m",
    "plaats": "Nieuwpoort",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.13.43,94\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "2 Engelse mijl",
    "plaats": "Nieuwpoort",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-2013",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.14.09,97\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "2 Engelse mijl",
    "plaats": "Nieuwpoort",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"20,13\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Hamerslingeren",
    "plaats": "Ieper",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.19,35\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 500 m",
    "plaats": "Ieper",
    "naam": "An Devos"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.44,62\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "4 x 100 m",
    "plaats": "Sint-Lambrechts-Woluwe - Fallonstadion",
    "naam": "Vercruysse, Melsens, Devos, Witdouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.15,78\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 800 m",
    "plaats": "Sint-Lambrechts-Woluwe - Fallonstadion",
    "naam": "Fonteyne, Masschelein M., De Cooman C., Verhaeghe F."
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "25-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.41,8\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "4 x 60 m",
    "plaats": "Moeskroen",
    "naam": "Verfaillie, Deceuninck, Demuynck, Peccue"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.51,8\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Fallonstadion",
    "naam": "Wielfaert Sof., RomeroFernandez, Ververken, Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "NAT.CRIT.",
    "handgeklokt": false,
    "datum": "25-09-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,45\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Hoogspringen",
    "plaats": "Nijvel",
    "naam": "MEERSCHAUT Roselinde"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,78\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Verspringen",
    "plaats": "Deinze",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "25-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.05,19\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "1 000 m",
    "plaats": "Nijvel",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-02-2000",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.25,80\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "200 m",
    "plaats": "Évry (FR)",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-04-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.22\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 400 m",
    "plaats": "Gent",
    "naam": "Joye, Walleghem, Verhaeghe, Soenen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-04-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.10,30\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "80 m",
    "plaats": "Tielt",
    "naam": "Nuytten Yaron"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-05-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.71,94\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m horden",
    "plaats": "Oostende",
    "naam": "Marleen Depuydt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.12,95\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "800 m",
    "plaats": "Oordegem",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"38,34\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Discuswerpen",
    "plaats": "Tielt",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-06-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.81,37\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "400 m",
    "plaats": "Brugge",
    "naam": "Bossue Patrick"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Meeting Nocturne",
    "handgeklokt": false,
    "datum": "26-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.35,87\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "300 m",
    "plaats": "Meeting Nocturne",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-07-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,82\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Verspringen",
    "plaats": "Herve",
    "naam": "Ghesquière Peter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-08-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.21.03,8\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Tielt",
    "naam": "Vanacker, Vannieuwenhuyse, Lemaire, Dekeersmaeker"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-08-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.15,84\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Amersfoort (NL)",
    "naam": "Declerck Peter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-08-2006",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.55,61\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 000 m",
    "plaats": "Waregem",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,71\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "100 m",
    "plaats": "Deinze",
    "naam": "Morena Vanhoorne"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.12,70\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "100 m",
    "plaats": "Oordegem",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.09,01\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m",
    "plaats": "Oostende",
    "naam": "Emily Laheye"
  },
  {
    "geslacht": "Mannen",
    "evenement": "1,00m",
    "handgeklokt": false,
    "datum": "26-09-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,22\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "110 m horden",
    "plaats": "Merksem",
    "naam": "Goudeseune Jeroen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.57,28\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "600 m",
    "plaats": "Kortrijk",
    "naam": "Vantomme Lennert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.41,13\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "300 m",
    "plaats": "Nijvel",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.08,47\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "60 m",
    "plaats": "Schaarbeek",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,95\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Kogelstoten",
    "plaats": "Brussel",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,86\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Verspringen",
    "plaats": "Brussel",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2682 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Vierkamp",
    "plaats": "Brussel",
    "naam": "Van de Weghe Jarl"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.18,42\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "800 m",
    "plaats": "Wervik",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"6749 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Tienkamp",
    "plaats": "Deinze",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,32\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Pascal Plets"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2013",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.07.32,81\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Gent",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2014",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.04.49,64\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Gent",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.83,65\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.27,17\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.55,06\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Gent",
    "naam": "Koen De Ridder"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.46,17\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Gent",
    "naam": "Deleu Ilke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,41\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.09.41,97\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "3 000 m",
    "plaats": "Gent",
    "naam": "Mathieu Lazou"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-12-2021",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.22,91\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-01-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,75\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Nico Alleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-01-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,85\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Kogelstoten 4kg",
    "plaats": "Ieper",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-01-2002",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.50,56\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Koen Polley"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,51\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Timon Spriet"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2 872 pnt\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Vijfkamp",
    "plaats": "Gent",
    "naam": "Timon Spriet"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.16.35,55\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "5 000 m",
    "plaats": "Roeselare",
    "naam": "Lietaert Alexander"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.38,71\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "300 m",
    "plaats": "Roeselare",
    "naam": "Carly Jan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,04\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Verspringen",
    "plaats": "Roeselare",
    "naam": "Carly Jan"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-07-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.56,70\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "800 m",
    "plaats": "Aalst",
    "naam": "Louwagie Patrick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-07-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.31,1\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 500 m",
    "plaats": "Brussel",
    "naam": "Monique Seys"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-07-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.14,1\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "3 000 m",
    "plaats": "Gent",
    "naam": "Roelens Noël"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-07-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.40,37\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "300 m",
    "plaats": "Oudenaarde",
    "naam": "Verschuere Clotilde"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-08-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"23,28\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Discuswerpen",
    "plaats": "Ieper",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-08-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,45\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-08-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.17,87\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "100 m",
    "plaats": "Ieper",
    "naam": "Bossue Patrick"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-08-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8,08\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-09-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,62\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Hockeywerpen",
    "plaats": "Waregem",
    "naam": "Els Bouchaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-09-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.09,68\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Zweedse estafette",
    "plaats": "Ieper",
    "naam": "DeCooman C (sen), Allemeersch K(sen), Allemeersch D (jun), VanDaele F (jun)"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-09-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.49,38\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Zweedse estafette",
    "plaats": "Ieper",
    "naam": "Wielfaert Si. Peene C., Mulleman C., Witdouck T."
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-09-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15984\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 uur",
    "plaats": "Oudenaarde",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-09-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"17400\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 uur",
    "plaats": "Oudenaarde",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-01-1996",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.27,32\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-01-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.05.01,24\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 500 m",
    "plaats": "Gent",
    "naam": "Demasure Sien"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-02-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,19\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Kogelstoten 3kg",
    "plaats": "Ieper",
    "naam": "Manuella RomeroFernandez"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-02-2016",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.60,74\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Amber Nuytten"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-02-2017",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,61\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Sarah Duyck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-05-2005",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.14,67\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "2 000 m",
    "plaats": "Zele",
    "naam": "Demuynck Eddy"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-05-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.00,83\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "800 m",
    "plaats": "Oordegem",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-05-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.53,78\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "800 m",
    "plaats": "Oordegem",
    "naam": "Vanhoucke Yngwie"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-05-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9,51\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Speerwerpen 600g",
    "plaats": "Izegem",
    "naam": "Sylvie Lefieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "BvV Masters - Afdeling 1",
    "handgeklokt": false,
    "datum": "28-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.07,88\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "3 000 m",
    "plaats": "Lokeren",
    "naam": "Decoster Frédéric"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "1°Memorial J. Pauwelyn -17° L.Denys",
    "handgeklokt": false,
    "datum": "28-05-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,79\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Kogelstoten",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/39115/Kogel/#330170_2",
    "plaats": "Izegem",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "1°Memorial J. Pauwelyn -17° L.Denys",
    "handgeklokt": false,
    "datum": "28-05-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,49\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Speerwerpen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/39115/330170/?r#losonderdeel_39_",
    "plaats": "Izegem",
    "naam": "Desseyn Daphne"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-06-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"33,88\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Discuswerpen",
    "plaats": "Izegem",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-06-2009",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.46,89\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 000 m",
    "plaats": "Roeselare",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-06-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.13,35\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "80 m horden",
    "plaats": "PK",
    "naam": "Lechantre Allan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "PK",
    "handgeklokt": false,
    "datum": "28-06-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,65\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Verspringen",
    "plaats": "PK",
    "naam": "Stacey Vanrobaeys"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-07-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.28,9\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "2 000 m steeplechase",
    "plaats": "Waregem",
    "naam": "Bouton Geert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,10\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Brugge",
    "naam": "Alleman Nico"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.45,03\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 100 m",
    "plaats": "Zwevegem",
    "naam": "Melsens, Devos, Vandaele, Witdouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.35,14\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "4 x 60 m",
    "plaats": "Zwevegem",
    "naam": "Verleyen, Depuydt, Polley, Meul"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.53,0\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 100 m",
    "plaats": "Zwevegem",
    "naam": "Thevelein P., Meuleman R., DeSplenter S., Wielfaert Si."
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.36,27\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "4 x 60 m",
    "plaats": "Zwevegem",
    "naam": "Vanzieleghem, Demuynck, Clarebout, Laheye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.49,50\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "2 000 m",
    "plaats": "Oudenaarde",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.64,65\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "400 m",
    "plaats": "Nieuwpoort",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.07,72\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "800 m",
    "plaats": "Ieper",
    "naam": "Dekeersmaecker Rudy"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"39,95\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hamerslingeren 3kg",
    "plaats": "Eeklo",
    "naam": "Birgit Dieryck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.27,21\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "3 x 600 m",
    "plaats": "Waregem",
    "naam": "Lieke Lietaert, Alexine Kesteloot, Fran Van Hollebeke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.50,76\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "600 m",
    "plaats": "Oudenaarde",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.23,69\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "600 m",
    "plaats": "Oudenaarde",
    "naam": "Gayse Brent"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-09-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.57,34\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "4 x 800 m",
    "plaats": "Heizel",
    "naam": "Russe, Catry, Rambour, Busschaert"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Stadium",
    "handgeklokt": false,
    "datum": "28-09-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.62,88\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "400 m horden",
    "plaats": "Stadium",
    "naam": "Ghesquière Regis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-09-1996",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.59,19\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "400 m",
    "plaats": "Dilbeek",
    "naam": "Siska Wielfaert"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-09-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.10.13,59\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 800 m",
    "plaats": "Heizel",
    "naam": "Beele, Walleghem, Verhaeghe, Joye"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-10-2009",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.02.36,87\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "800 m",
    "plaats": "Gent",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-12-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.56,80\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 000 m",
    "plaats": "Aalst",
    "naam": "Jaimie Demuynck"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-12-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.03,92\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 000 m",
    "plaats": "Aalst",
    "naam": "Jaimie Demuynck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-12-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.36,75\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Brent Gayse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-12-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,02\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Bieke Michiel"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.26,11\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "200 m",
    "plaats": "Gent",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-01-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.08,19\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "60 m",
    "plaats": "Gent",
    "naam": "Caroline Buyse"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-01-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.34.35,87\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "5 km snelwandelen",
    "plaats": "Gent",
    "naam": "Renaud Vandenberghe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-02-2004",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,88\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-02-2004",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2987 pnt\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Vijfkamp",
    "plaats": "Gent",
    "naam": "Katrien Sieuw"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1 633 pnt\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Vierkamp",
    "plaats": "Gent",
    "naam": "Thor Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,34\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Kogelstoten",
    "plaats": "Gent",
    "naam": "Jarl Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,62\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Verspringen",
    "plaats": "Gent",
    "naam": "Jarl Van de Weghe"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2 396 pnt\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "Vierkamp",
    "plaats": "Gent",
    "naam": "Jarl Van de Weghe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-04-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.59,68\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m",
    "plaats": "Kortrijk",
    "naam": "Quatacker Lore"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-05-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.12.32,56\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "3 000 m",
    "plaats": "Izegem",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,71\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Hoogspringen",
    "plaats": "Gateshead (GB)",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4835 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Tienkamp",
    "plaats": "Gateshead (GB)",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.53,4\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Oostende",
    "naam": "Devolder, Polfliet, Camerlynck, DePonthière"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.35,0\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Oostende",
    "naam": "Polfliet, DePonthière, Gouwy, Vanhulle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.58,2\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "4 x 200 m",
    "plaats": "Oostende",
    "naam": "Devos, Ververken, Vannieuwenhuyse, Camerlynck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.33,6\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "4 x 200 m",
    "plaats": "Oostende",
    "naam": "Gouwy, Reynaert, Sedeyn, Vanhulle"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.50,70\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Waregem",
    "naam": "Decoene Lieven"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.25,97\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "200 m",
    "plaats": "Waregem",
    "naam": "Nathalie Bobowsky"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,49\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Lanaken",
    "naam": "Ine Mylle"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-09-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.56,74\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "2 000 m",
    "plaats": "Wervik",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.40,55\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Wervik",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.15.03,79\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "3 000 m",
    "plaats": "Wervik",
    "naam": "Polley Boudewijn"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.00,53\""
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "600 m",
    "plaats": "Nijvel",
    "naam": "Van Hollebeke Fran"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"18,00\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Discuswerpen",
    "plaats": "Wervik",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-12-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.13,34\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "60 m horden",
    "plaats": "Gent",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-12-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.43,30\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "300 m",
    "plaats": "Gent",
    "naam": "Vanhoorne Morena"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-01-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.00.49,71\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "400 m",
    "plaats": "Gent",
    "naam": "Simon Versavel"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-04-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,75\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Hoogspringen",
    "plaats": "Ieper",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-04-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,76\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "Kogelstoten",
    "plaats": "Ieper",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-04-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.03.32,68\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "1 000 m",
    "plaats": "Oordegem",
    "naam": "Peene Christine"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-04-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.48,52\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "2 000 m",
    "plaats": "Roeselare",
    "naam": "Goudeseune Jonathan"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-05-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"34,29\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Discuswerpen",
    "plaats": "Izegem",
    "naam": "Heidi Vanslembrouck"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-05-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.23,58\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "200 m",
    "plaats": "Izegem",
    "naam": "Versavel Simon"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"12224 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Benjamins",
    "onderdeel": "Driekamp",
    "plaats": "Oostende",
    "naam": "Depuydt Hannes"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.03,39\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "800 m",
    "plaats": "Waregem",
    "naam": "Masschelein Marc"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,90\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Verspringen",
    "plaats": "Booischot",
    "naam": "Allemeersch Klaas"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.33,15\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Poperinge",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.29,80\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Poperinge",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,22\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "100 m",
    "plaats": "Poperinge",
    "naam": "Accou Anne-Marie"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,67\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Verspringen",
    "plaats": "Poperinge",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.27,45\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Poperinge",
    "naam": "Lore Quatacker"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"23,88\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Discuswerpen",
    "plaats": "Poperinge",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"20,71\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "Speerwerpen 600g",
    "plaats": "Poperinge",
    "naam": "Saskia De Splenter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.33,47\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "600 m",
    "plaats": "Gentbrugge",
    "naam": "De Ridder Koen"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,58\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "100 m horden",
    "plaats": "Gateshead (GB)",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,69\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Discuswerpen",
    "plaats": "Gateshead (GB)",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-07-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,10\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gateshead (GB)",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-07-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,50\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Kogelstoten",
    "plaats": "Knokke",
    "naam": "Nuytten Yaron"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-07-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3458 pnt\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Vijfkamp",
    "plaats": "Knokke",
    "naam": "Nuytten Yaron"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-08-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.34,2\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "800 m",
    "plaats": "Waregem",
    "naam": "Christelle Deturc"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-08-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.32.48,7\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "10 000 m",
    "plaats": "Waregem",
    "naam": "Decorte Paul"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-08-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.24,9\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Ninove",
    "naam": "Deknudt Bart"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-08-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.29,24\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "3 000 m",
    "plaats": "Waregem",
    "naam": "Verleyen Philippe"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Jeugdtrofee",
    "handgeklokt": false,
    "datum": "30-08-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.22,18\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "150 m horden",
    "plaats": "Jeugdtrofee",
    "naam": "Stacey Vanrobaeys"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-08-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.14,83\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "100 m",
    "plaats": "Ieper",
    "naam": "Vandekerkhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "28-08-2015",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.30,60\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "200 m",
    "plaats": "Ieper",
    "naam": "Vandekerkhove Filiep"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-09-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,10\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Oostende",
    "naam": "Devos Dieter"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,31\""
    },
    "einddatum": "",
    "categorie": "Seniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Izegem",
    "naam": "Plets Pascal"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.07.10,64\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Wervik",
    "naam": "An Devos"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-09-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.20,19\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Wervik",
    "naam": "Amber Nuytten"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-11-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"00.03.24,44\""
    },
    "einddatum": "",
    "categorie": "Pupillen",
    "onderdeel": "1 000 m",
    "plaats": "Gent",
    "naam": "Fran Van Hollebeke"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "26-04-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.51,58\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "300 m",
    "plaats": "Tielt",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "30-12-1899",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.51,78\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "300 m",
    "plaats": "Oudenaarde",
    "naam": "Chantal Mulleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-01-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,88\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "Hoogspringen",
    "plaats": "Gent",
    "naam": "Nico Alleman"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-01-1998",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,8\""
    },
    "einddatum": "",
    "categorie": "Cadetten",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Gent",
    "naam": "Jeroen Devos"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-05-1956",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.06.01,27\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "2 000 m",
    "plaats": "Waregem",
    "naam": "Vandekerckhove Filiep"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-05-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"34,64\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Discuswerpen",
    "plaats": "Oostende",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-05-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,39\""
    },
    "einddatum": "",
    "categorie": "Juniores",
    "onderdeel": "Kogelstoten",
    "plaats": "Oostende",
    "naam": "Ilse Geldhof"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-05-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.25,38\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "200 m",
    "plaats": "Stadium",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-05-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.08.37,79\""
    },
    "einddatum": "",
    "categorie": "Scholieren",
    "onderdeel": "2 000 m steeplechase",
    "plaats": "Roeselare",
    "naam": "Kim Vandekerckhove"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-07-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,42\""
    },
    "einddatum": "",
    "categorie": "M50",
    "onderdeel": "Discuswerpen",
    "plaats": "Oostende",
    "naam": "Rosewitha Pannekoucke"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,68\""
    },
    "einddatum": "",
    "categorie": "M40",
    "onderdeel": "Hamerslingeren",
    "plaats": "Tourcoing (FR)",
    "naam": "Ghesquière Régis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.05.30,90\""
    },
    "einddatum": "",
    "categorie": "M55",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Beernem",
    "naam": "Engelbert Kurt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-08-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.75,99\""
    },
    "einddatum": "",
    "categorie": "M45",
    "onderdeel": "400 m",
    "plaats": "Waregem",
    "naam": "Christine Peene"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-08-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.14,55\""
    },
    "einddatum": "",
    "categorie": "M35",
    "onderdeel": "100 m",
    "plaats": "Ieper",
    "naam": "Evelyne Delva"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-08-2014",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.10,09\""
    },
    "einddatum": "",
    "categorie": "Miniemen",
    "onderdeel": "60 m horden",
    "plaats": "Beveren",
    "naam": "Stacey Vanrobaeys"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "31-08-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.01.45,19\""
    },
    "einddatum": "",
    "categorie": "M60",
    "onderdeel": "400 m",
    "plaats": "Waregem",
    "naam": "Mulleman Chantal"
  }
]

export default resultaten

