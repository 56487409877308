const categories = [
"Benjamins",
"Pupillen",
"Miniemen",
"Cadetten",
"Scholieren",
"Juniores",
"Seniores",
"M35",
"M40",
"M45",
"M50",
"M55",
"M60",
"M65",
]
export default categories

